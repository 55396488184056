<app-evida-error [showRetryButton]="false">
  <ng-container [ngSwitch]="errorCode">
    <div *ngSwitchCase="reponseErrors.DECOPLING_UNAUTHORIZED">
      <div [innerHTML]="'flows.cancellation.steps.authentificationError' | translate"></div>
      <div class="container-no-gutter pt-small">
        <div class="flex flex-row justify-start">
          <div class="mb10 flex-col md:w-auto">
            <app-evida-button type="primary" (clicked)="backToHomepage()">{{ 'shared.buttons.goto_current_my_evida' |
              translate }}</app-evida-button>
          </div>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="reponseErrors.SESSION_TIMEOUT">
      <h2>{{ 'flows.monthlyReading.timeout.header' | translate}}</h2>
      <p>
        {{ 'flows.monthlyReading.timeout.body' | translate}}
      </p>
      <div class="container-no-gutter" *ngIf="url">
        <div class="flex flex-row justify-start">
          <div class="mb10 flex flex-col w-full md:w-auto">
            <app-evida-button type="primary" (clicked)="retry()">
              {{ 'shared.buttons.retry' | translate }}
            </app-evida-button>
          </div>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="reponseErrors.VERIFICATION_CODE_SESSION_TIMEOUT">
      <h2>{{ 'flows.monthlyReading.timeout.header' | translate}}</h2>
      <p>
        {{ 'flows.monthlyReading.timeout.body2' | translate}}
      </p>
      <div class="container-no-gutter" *ngIf="url">
        <div class="flex flex-row justify-start">
          <div class="mb10 flex flex-col w-full md:w-auto">
            <app-evida-button type="primary" (clicked)="retry()">
              {{ 'shared.buttons.retry' | translate }}
            </app-evida-button>
          </div>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="reponseErrors.METER_READINGS_NOT_FOUND">
      <h3 translate="flows.reading.dashboard.empty.title"></h3>
      <div class="mt-20px">
        <p class="body-small" translate="siteError.meterNumberNotFound.title"></p>
        <ul class="pl-20px">
          <li class="body-small" translate="siteError.meterNumberNotFound.reason1"></li>
          <li class="body-small" translate="siteError.meterNumberNotFound.reason2"></li>
        </ul>
        <p class="body-small" translate="siteError.meterNumberNotFound.body">
          <a [href]="evidaLoginLink" translate="siteError.meterNumberNotFound.link"></a>
        </p>
      </div>
    </div>

    <div *ngSwitchCase="reponseErrors.INTERNAL_SERVER_ERROR">
      <h2>Øv, noget gik galt!</h2>
      <p class="error-info">{{ errorInfo }}</p>
      <div class="flex flex-col mb-10px md:mb-0" *ngIf="correlationId">
        <span translate="shared.errormessages.correlationId"></span>
        <code>{{ correlationId }}</code>
        <span class="mt-5px">{{'shared.errormessages.date' | translate : { date: errorDate.date } }}</span>
        <span>{{'shared.errormessages.component' | translate }}</span>
        <app-evida-button class="mt-medium" type="secondary" (clicked)="onCopyCorrelationId()">
          {{ 'shared.buttons.copyToClipboard' | translate }}
        </app-evida-button>
      </div>

      <div class="container-no-gutter" *ngIf="url">
        <div class="flex flex-row justify-end">
          <div class="mb-10px flex flex-col w-full md:w-auto">
            <app-evida-button type="primary" (clicked)="retry()">
              {{ 'shared.buttons.retry' | translate }}
            </app-evida-button>
          </div>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="reponseErrors.INSTALLATIONS_NOT_FOUND">
      <h3 translate="newCancellationFlow.noInstallations.title"></h3>
      <div class="mt-20px">
        <p class="body-small" translate="newCancellationFlow.noInstallations.body"></p>
        <div class="container-no-gutter mt-20px">
          <ng-container *ngTemplateOutlet="buttons"></ng-container>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="reponseErrors.CANT_SUBMIT_TERMINATION_READING_THIS_DATE">
      <h3 translate="flows.terminationReading.error.title"></h3>
      <div class="mt-20px">
        <p class="body-small" [innerHTML]="'flows.terminationReading.error.body' | translate"></p>
        <div class="container-no-gutter mt-20px">
            <div class="flex flex-row justify-end">
              <div class="mb10 flex flex-col w-full sm:w-auto">
                <app-evida-button type="primary" (clicked)="previousPage()">
                  {{ 'shared.buttons.back' | translate }}
                </app-evida-button>
              </div>
            </div>
        </div>
      </div>
    </div>

    <div *ngSwitchDefault>
      <h2>Øv, noget gik galt!</h2>
      <p>
        Der er sket en fejl og vi arbejder på at løse problemet. Vi forventer at være klar igen snarest! Hvis du har
        brug for omgående hjælp er du velkommen til at kontakte kundeservice på 77 89 90 00.
      </p>
      <p *ngIf="errorCode">
        Fejlkode: <code class="selectAll">{{ errorCode }}</code>
        <br>
        Fejl besked: <code class="selectAll">{{ errorInfo }}</code>
        <br>
        <br>
      </p>
      <div class="container-no-gutter">
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </div>
    </div>
  </ng-container>
</app-evida-error>

<!-- Navigation buttons -->
<ng-template #buttons>
  <div class="flex flex-row justify-start">
    <div class="mb10 mr-10px flex flex-col w-full md:w-auto">
      <app-evida-button type="primary" (clicked)="previousPage()">
        {{ 'shared.buttons.history_back' | translate }}
      </app-evida-button>
    </div>
    <div class="mb10 flex flex-col w-full md:w-auto">
      <app-evida-button type="secondary" (clicked)="backToHomepage()">
        {{ 'shared.buttons.to_frontpage' | translate }}
      </app-evida-button>
    </div>
  </div>
</ng-template>
