import {
  BehaviorSubject,
  MonoTypeOperatorFunction,
  Observable,
  ObservableInput,
  ObservedValueOf,
  OperatorFunction,
  Subject,
  of,
  throwError,
  timer
} from 'rxjs'
import { catchError, mergeMap, retryWhen, tap } from 'rxjs/operators'

import { HttpErrorResponse } from '@angular/common/http'
import { Router } from '@angular/router'
import { ReponseErrors } from '../soft-login/models/error.model'
import { Logger } from './logger'
import { RedirectTo } from './redirect-to'

export interface RetryOptions {
  retryTimes?: number;
  codesToRetryOn?: Array<number>;
  delay?: number;
}
export class RxJS {

  public static toBehaviorSubject<T>(
    observable: Observable<T>,
    defaultValue: T | null = null
  ): BehaviorSubject<T | null> {
    const bSubject = new BehaviorSubject<T | null>(defaultValue)

    const subscription = observable.subscribe(
      (value: T | null) => bSubject.next(value),
      error => bSubject.error(error),
      () => {
        subscription.unsubscribe()
        bSubject.complete()
      }
    )

    return bSubject
  }

  public static toSubject<T>(observable: Observable<T>): Subject<T> {
    const subject = new Subject<T>()

    const subscription = observable.subscribe(
      (value: T) => subject.next(value),
      error => subject.error(error),
      () => {
        subscription.unsubscribe()
        subject.complete()
      }
    )
    return subject
  }

  public static logAndRethrow
    <T, O extends ObservableInput<any>>(logger: Logger): OperatorFunction<T, T | ObservedValueOf<O>> {
      return catchError(error => {
        logger.error(error)
        return throwError(() => error)
      })
    }

  /**
   * @returns a default implementation of the pipe catchError
   * which checks for the status to be 404 and returns @param defaultValue
   */
  public static catch404AndReturn
    <T, R>(defaultValue: R): OperatorFunction<T, T | ObservedValueOf<ObservableInput<any>>> {
      return catchError<T, Observable<any>>((error) => {
        if (error?.status === 404) { return of(defaultValue) }
        return throwError(() => error)
      })
    }

  /**
   * @returns a default implementation of the pipe catchError
   * which checks for the status to be 401 and returns @param defaultValue
   */
  public static catch401AndReturn
  <T, R>(defaultValue: R): OperatorFunction<T, T | ObservedValueOf<ObservableInput<any>>> {
    return catchError<T, Observable<any>>((error) => {
      if (error?.status === 401) { return of(defaultValue) }
      return throwError(() => error)
    })
  }


  /**
   * @returns a call to RxJS.retryWith with default parameters of:
   * delay: 500,
   * codesToRetryOn: [500],
   * retryTimes: 3
   */
  public static defaultRetryLogic<T>(): MonoTypeOperatorFunction<T> {
    return RxJS.retryWith({
      delay: 500,
      codesToRetryOn: [500],
      retryTimes: 2
    })
  }

  /**
   * Custom implementation of the rxjs retryWhen.
   * Simply use it in your pipe() method like:
   *
   * @example
   * obs$.pipe(
   *    RxJS.retryWith({
   *      delay: 500,
   *      codesToRetryOn: [500],
   *      retryTimes: 3
   *    }),
   *    catchError( err => {…})
   * )
   * @param options lets the developer specify:
   * - the maximum amount of times we can retry
   * - a base delay between retries
   * - an array of status codes where retrying on makes sense and
   * @returns the custom implementation of retryWhen with exponential backoff
   */
  public static retryWith<T>(options: RetryOptions): MonoTypeOperatorFunction<T> {
    // If unspecified, don't delay between retries
    const delay = options.delay ?? 0
    // If unspecified, don't match any statusCode
    const userSpecifiedRetryCodes = options.codesToRetryOn != null && options.codesToRetryOn.length > 0
    // If unspecified default to one retry .
    const maxRetryTimes = options.retryTimes ?? 1

    return retryWhen<T>(errStream$ => errStream$.pipe(
      mergeMap(
        (error, emissionIndex) =>
          RxJS.decideIfCanRetry(
            error, emissionIndex, delay,
            maxRetryTimes, userSpecifiedRetryCodes,
            options.codesToRetryOn
          )
      )
    ))
  }

  /**
   * This pipe operator is intended to be used for Observables<HttpErrorResponse>
   *
   * @param router: required to perform redirect.
   */
  public static redirectIfError500(router: Router): MonoTypeOperatorFunction<any> {
    return tap<any>(err => {
      if (parseInt(err?.status, 10) === 500) {
        RedirectTo.internalServerError(router, err)
      }
    })
  }

  /**
   * This pipe operator is intended to be used for Observables<HttpErrorResponse>
   *
   * @param router: required to get the URL where the error occured.
   */
  public static redirectIfTimeout(router: Router): MonoTypeOperatorFunction<any> {
    return tap<any>(err => {
      if (parseInt(err?.status, 10) === 498) {
        RedirectTo.sessionTimeout(router, {
          state: {
            errorCode: ReponseErrors.SESSION_TIMEOUT,
            url: router.url
          }
        })
      }
    })
  }



  private static decideIfCanRetry(
    error: any,
    emissionIndex: number,
    delay: number,
    maxRetryTimes: number,
    userSpecifiedRetryCodes: boolean,
    codesToRetryOn: number[]
  ): Observable<any> {
    const currentRetry = emissionIndex + 1 // indexes start at 0
    const exponentialBackoffDelay = currentRetry * delay
    const weMaxedOutRetries = currentRetry > maxRetryTimes
    // We've maxed out our retries; throw the error no matter what!
    if (weMaxedOutRetries) {
      return throwError(() => error)
    }
    // We still have retries; we should check the error status code if
    // 1. the developer specified status codes AND it's an http error.
    const shouldCheckStatusCode = userSpecifiedRetryCodes && error instanceof HttpErrorResponse
    if (shouldCheckStatusCode) {
      const httpErr = error as HttpErrorResponse
      // We can retry, if the current error code is inside the passed list
      const canRetryOnCurrentHttpCode = codesToRetryOn?.includes(httpErr.status) ?? false
      if (canRetryOnCurrentHttpCode) {
        return timer(exponentialBackoffDelay)
      } else {
        return throwError(() => error)
      }
    }
    // The error wasn't an HTTP error, we're gonna retry after the delay
    return timer(exponentialBackoffDelay)
  }
}
