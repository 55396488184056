import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { EMPTY, Observable, throwError } from 'rxjs'
import { LoadingStateService } from 'src/app/shared/services/loading-state.service'

import { Injectable } from '@angular/core'
import { NavigationExtras, Router } from '@angular/router'
import { catchError } from 'rxjs/operators'
import { Logger } from 'src/app/utils/logger'
import { RedirectTo } from 'src/app/utils/redirect-to'
import { Utils } from 'src/app/utils/utils'

// When the client's session times out
export const EXPIRED_SESSION = 403
// When a user has no access to assign a new installation
export const NO_ACCESS = 410
// When a user in Xcellent has been locked (marked as 'Spærret')
export const USER_LOCKED = 423
// This error code matches when evida can't keep up
export const SITE_BUSY = 503
// This error code matches when requests are timing out
export const XELLENT_TIMED_OUT = 529

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private logger: Logger, private loading: LoadingStateService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(
        error =>
          error instanceof HttpErrorResponse
            ? this.handleError(error)
            : throwError(() => error)
      )
    )
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    // Do not intercept endpoints that ends with download, status, download-id
    const urlObj = new URL(error.url)
    if (urlObj && urlObj.pathname.split('/').pop().match(/download$|status$|download-id$/gi)) {
      return throwError(() => error)
    }

    switch(error.status) {
    case NO_ACCESS:
      this.loading.setNotLoading()
      return this.redirectToNoAccess(error)
    case USER_LOCKED:
      // TODO: Make this better and narrow it down to the reading plan call:
      this.loading.setNotLoading()
      return this.redirectToBlocked(error)
    case SITE_BUSY:
      // TODO: Make this better and narrow it down to the reading plan call:
      this.loading.setNotLoading()
      return this.redirectToBusy(error)
    case XELLENT_TIMED_OUT:
      // TODO: Make this better and narrow it down to the reading plan call:
      this.loading.setNotLoading()
      return this.redirectToXellentTimeout(error)
    case EXPIRED_SESSION:
      this.loading.setNotLoading()
      return this.redirectToExpiredSession(error)
    default:
      return throwError(() => error)
    }
  }

  redirectToNoAccess(error: HttpErrorResponse): Observable<any> {
    this.logger.debug(`Http Status: ${NO_ACCESS} - User has no access`, error)
    RedirectTo.noAccess(this.router, this.wrapCorrelationIdIntoNavigationExtras(error))
    return EMPTY
  }

  redirectToBlocked(error: HttpErrorResponse): Observable<any> {
    this.logger.debug(`Http Status: ${USER_LOCKED} - User blocked`, error)
    RedirectTo.userBlocked(this.router, this.wrapCorrelationIdIntoNavigationExtras(error))
    return EMPTY
  }

  redirectToBusy(error: HttpErrorResponse): Observable<any> {
    this.logger.debug(`Http Status: ${SITE_BUSY} - busy`, error)
    RedirectTo.serverBusy(this.router, this.wrapCorrelationIdIntoNavigationExtras(error))
    return EMPTY
  }

  redirectToXellentTimeout(error: HttpErrorResponse): Observable<any> {
    this.logger.debug(`Http Status: ${XELLENT_TIMED_OUT} - Xellent timing out`, error)
    RedirectTo.xellentTimingOut(this.router, this.wrapCorrelationIdIntoNavigationExtras(error))
    return EMPTY
  }

  redirectToExpiredSession(error: HttpErrorResponse): Observable<any>{
    this.logger.debug(`Http Status: ${EXPIRED_SESSION} - Client's session timing out`, error)
    RedirectTo.expiredSession(this.router, this.wrapCorrelationIdIntoNavigationExtras(error))
    return EMPTY
  }

  // Wraps the correlation ID behing the nested objects for the router's state
  private wrapCorrelationIdIntoNavigationExtras(error: HttpErrorResponse): NavigationExtras {
    return {
      state: {
        error: {
          correlationId: Utils.getCorrelationIdFromError(error),
          date: Utils.getDateFromError(error)
        }
      }
    }
  }
}
