import { DatePipe } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { Router } from '@angular/router'
import { Utils } from 'src/app/utils/utils'
import { ErrorDate } from './error-date.model'

@Component({
  selector: 'app-evida-error',
  templateUrl: 'evida-error.component.html',
  styleUrls: ['evida-error.component.scss'],
})
export class EvidaErrorComponent {
  @Input() public icon = 'evida_error_screen'
  @Input() public error: any
  @Input() public showRetryButton = true
  @Input() public retryButtonText = 'shared.buttons.retry'
  @Input() public showCancelButton = false
  @Input() public cancelButtonText = 'shared.buttons.cancel'
  @Input() public errorCode: string | null = null
  @Input() public showCorrelationId = true

  @Output() public retryClicked = new EventEmitter()
  @Output() public cancelClicked = new EventEmitter()


  private correlationIdFromRouterState: string | null
  private errorDateFromRouterState: string | null

  constructor(private router: Router, private snack: MatSnackBar, private datePipe: DatePipe) {
    /**
     * It would be much more preferrable to not keep any state, but the method
     * Router#getCurrentNavigation() will return `null` instead of the latest navigation
     * after the navigation has ended, hence we need to save whatever was passed in when the
     * navigation is still ongoing.
     */
    this.correlationIdFromRouterState = this.getCorrelationIdFromRouterState(this.router)
    this.errorDateFromRouterState = this.getDateFromRouterState(this.router)
  }

  public get correlationId(): string | null {
    switch (this.error) {
    // If we have nothing passed from the @Input annotation
    case null:
    case undefined:
      // try getting it from the router state
      return this.correlationIdFromRouterState
    default:
      // If we had an error passed as @Input, try getting it from it
      const correlationIdFromError = this.getCorrelationIdFromError(this.error)
      // If null-ish fallback to whatever came from the router
      return correlationIdFromError ?? this.correlationIdFromRouterState
    }
  }

  public get errorDate(): ErrorDate | null {
    let date: string

    switch (this.error) {
    // If we have nothing passed from the @Input annotation
    case null:
    case undefined:
      // try getting it from the router state
      date = this.errorDateFromRouterState
      break
    default:

      // If we had an error passed as @Input, try getting it from it
      const dateFromError = this.getDateFromError(this.error)
      // If null-ish fallback to whatever came from the router
      date = dateFromError ?? this.errorDateFromRouterState
      break
    }
    return {
      date: date
    }
  }

  public primaryButtonClicked() {
    this.retryClicked.emit()
  }
  public secondaryButtonClicked() {
    this.cancelClicked.emit()
  }

  public onCopyCorrelationId() {
    if (this.correlationId) {
      Utils.copyToClipboard(this.correlationId)
      this.snack.open('Kopieret til udklipsholderen!', /*action*/ undefined, {
        duration: 1200,
      })
    }
  }

  private getCorrelationIdFromError(error: any): string | null {
    return Utils.getCorrelationIdFromError(error)
  }

  private getDateFromError(error: any): string | null {
    return Utils.getDateFromError(error)
  }

  private getCorrelationIdFromRouterState(router: Router): string | null {
    const correlationId = router.getCurrentNavigation()?.extras?.state?.correlationId
    // Replace undefined with null
    return correlationId ? correlationId : null
  }

  private getDateFromRouterState(router: Router): string | null {
    const date = router.getCurrentNavigation()?.extras?.state?.date
    return date ?? null
  }
}
