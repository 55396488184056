
export const EvidaEvents = {
  // Useless test events. To be removed
  Login: {
    Login: 'Login',
    LoginPrivate: 'Login-Privat',
    LoginBusiness :'Login-Erhverv'
  },
  Toolbar: {
    clicks: {
      logo: 'toolbar-logo-click',
      backToFrontpage: 'toolbar-back-to-frontpage-click',
    },
  },
  Footer: {
    clicks: {
      link: 'footer-link-click',
    },
  },
  SiteError: {
    clicks: {
      backToHomepage: 'site-error-backToHomepage-click',
    },
  },
  NotFound: {
    clicks: {
      backToHomepage: 'not-found-backToHomepage-click',
    },
  },
  LandingPage: {
    clicks: {
      backToHomepage: 'landing-page-backToHomepage-click',
    },
  },
  FAQ: {
    clicks: {
      link: 'faq-link-click',
    },
  },
  Termination: {
    backToFrontPage: {
      step1: 'Slutafl-Step1Info-TilbageTilForsiden',
      step2: 'Slutafl-Step2TastAfl-TilbageTilForsiden',
      step3: 'Slutafl-Step3Kvittering-TilbageTilForsiden'
    },
    terminationCardSelected: 'Slutafl-Oversigt-Aflæs',
    linkInfo: 'Slutafl-Step1Info-AendrDatoHer',
    terminationFlowStarted: 'Slutafl-Step1Info-AflaesHer',
    sendReading: 'Slutafl-Step2TastAfl-SendAflaesning',
    backToInfoPage: 'Slutafl-Step2TastAfl-Tilbage',
    downloadPDFEvent: 'Slutafl-Step3Kvittering-DownloadKvittering',
    backToFrontpage: 'Slutafl-Step3Kvittering-FortsaetTilMitEvida',
    backToOverview: 'Slutafl-Step3Kvittering-TilOversigt'
  },
  Decoupling: {
    userSignatureReady: 'decoupling-user-signature-ready',
    userSignatureCompleted: 'decoupling-user-signature-completed',
    userSignatureCancelled: 'decoupling-user-signature-cancelled',
    userEntersFlowFromChannel: 'decoupling-user-entered-from-{channel}',
    userEntersFlow: 'decoupling-flow-start',
    userAbandonsFlow: 'decoupling-flow-abandoned',
    userCompletesFlow: 'decoupling-flow-completed',
    userReceivedFlowType: 'decoupling-flow-received-flow-type',
    userSubmitInstallationForm: 'decoupling-flow-submit-installations-form',
    downloadPDFEvent: 'decoupling-flow-downloadPDF-event',
    clicks: {
      backFirstScreen: 'decoupling-flow-backCancellation-click',
      downloadPDF: 'decoupling-flow-downloadPDF-click',
      downloadPDFPreviouslyOrdered:
        'decoupling-flow-downloadPDF-previouslyOrdered-click',
    },
  },
  AnnualReading: {
    /** takes {channel} argument */
    userEntersFlowFromChannel: 'annual-flow-user-entered-from-{channel}',
    userEntersFlow:
      'annual-flow-start-{channel}' /** TODO: DO we need now, with the one above added */,
    /** takes {channel} argument */
    userEntersFlowAlreadyReadFromChannel:
      'annual-flow-alreadyRead-user-entered-from-{channel}',
    userCompletesFlow: 'annual-flow-completed',
    userAbandonsFlow: 'annual-flow-abandoned',
    annualFlowReadingSent: 'annual-flow-reading-sent',
    clicks: {
      // back
      next: 'annual-flow-next-click',
      // back
      back: 'annual-flow-back-click',
      // Ret din aflæsning
      fixReading: 'annual-flow-fixReading-click',
      // givOsBesked
      letUsKnow: 'annual-flow-letUsKnow-click',
      // fortsæt til mit evida
      continueToMitEvida: 'annual-flow-continueToMitEvida-click',
      backToFrontpage: 'annual-flow-backToFrontpage-click',
      downloadPDF: 'annual-flow-downloadPDF-click',
      sendReading: 'annual-flow-send-reading-click',
      sendDeviation: 'annual-flow-send-deviation-click',
    },
    WrongReciever: {
      userEntersFlowFromChannel: 'wrong-receiver-user-entered-from-{channel}',
    },
  },

  // TODO: Ask Heidi and remove / add event names based on our screens from figma!
  MonthlyReading: {
    /** takes {channel} argument */
    userEntersFlowFromChannel: 'monthly-flow-user-entered-from-{channel}',
    userEntersFlow:
      'monthly-flow-start-{channel}' /** TODO: DO we need now, with the one above added */,
    /** takes {channel} argument */
    userEntersFlowAlreadyReadFromChannel:
      'monthly-flow-alreadyRead-user-entered-from-{channel}',
    userCompletesFlow: 'monthly-flow-completed',
    userAbandonsFlow: 'monthly-flow-abandoned',
    dashboardRead: 'Månedelig-Oversigt-Aflæs',
    readingSend: 'Månedelig-Aflæsning-SendAflæsning',
    readingDeviationSend: 'Månedelig-Afvigelse-BekræftAflæsning',
    readingBack: 'Månedelig-Aflæsning-Tilbage',
    readingEditEnteredReading: 'Månedelig-Afvigelse-RetDinAflæsning',
    readingGetHelp: 'Månedelig-Aflæsning-FåHjælpTilAflæsning',
    readingDeviationGetHelp: 'Månedelig-Afvigelse-FåHjælpTilAflæsning',
    receiptDownload: 'Månedelig-Kvittering-DownloadKvittering',
    receiptGotoDashboard: 'Månedelig-Kvittering-TilOversigt',
    receiptLogOnMitEvida: 'Månedelig-Kvittering-LogIndPåMitEvida',
    receiptContinueToMitEvida: 'Månedelig-Kvittering-FortsætTilMitEvida',
    flowStart: 'Månedelig-StartFlow',
    flowCompleted: 'Månedelig-SlutKvitteringsside',
    flowLeaving: 'Månedelig-ForladtFlow',
    flowNoReading: 'Månedelig-IngenAktuelAflæsning',
    clicks: {
      // back
      next: 'monthly-flow-next-click',
      // back
      back: 'monthly-flow-back-click',
      // Ret din aflæsning
      fixReading: 'monthly-flow-fixReading-click',
      // givOsBesked
      letUsKnow: 'monthly-flow-letUsKnow-click',
      // fortsæt til mit evida
      continueToMitEvida: 'monthly-flow-continueToMitEvida-click',
      backToFrontpage: 'monthly-flow-backToFrontpage-click',
    },
    WrongReciever: {
      userEntersFlowFromChannel: 'wrong-receiver-user-entered-from-{channel}',
    },
  },
}

export interface FormatArgument {
  key: string;
  value: any;
}

export interface CancellationHeatingSourceAndRemainingDays {
  daysUntilCancellation: number;
  heatingSource: string;
}

export class EvidaEvent {
  /**
   * Formats a parametherised event.
   *
   * Let's say we have login-{email} as an event.
   * And then call this function as:
   *
   * @example
   * const formattedEvent = EvidaEvent.format(EvidaEvents.Login, {email: ourVariable});
   * GoogleAnalytics.TrackEvent(formattedEvent)
   *
   * @param event An event name that needs formatting.
   * @param formatArgs the values to be replaced by this function
   */
  public static format(event: string, ...formatArgs: FormatArgument[]): string {
    let result = event
    for (const formatArgument of formatArgs) {
      const token = `{${formatArgument.key}}`
      result = result.replace(token, formatArgument.value)
    }
    return result
  }
}
