import { HttpErrorResponse } from '@angular/common/http'
import { NavigationExtras, Router, UrlTree } from '@angular/router'
import { environment } from 'src/environments/environment'
import { LoginRoutes } from '../login/models/login-routes.model'
import { ShortLinkData } from '../short-link/short-link.service'
import { BaseReading, ReadingCustomerDetails } from '../soft-login/base-reading'
import { ReponseErrors } from '../soft-login/models/error.model'
import { ReadingRoutes } from '../soft-login/models/reading-routes.model'
import { Utils } from './utils'

export class RedirectTo {
  public static get loginRootFlow(): string {
    return `${LoginRoutes.login}/${LoginRoutes.flow.root}`
  }

  public static internalServerError(router: Router, error?: HttpErrorResponse): Promise<boolean> {
    const extras = error ? {
      state: {
        errorCode: ReponseErrors.INTERNAL_SERVER_ERROR,
        correlationId: Utils.getCorrelationIdFromError(error),
        errorInfo: error.message,
        url: router.url,
        date: Utils.getDateFromError(error)
      }
    }: undefined
    const urlTree = RedirectTo.errorUrlTree(router, extras)

    return router.navigateByUrl(urlTree, extras)
  }

  public static sessionTimeout(router: Router, extras?: NavigationExtras): Promise<boolean> {
    const timeoutExtras = {
      state: {
        errorCode: ReponseErrors.SESSION_TIMEOUT,
        url: router.url
      }
    }
    const actualExtras = extras ? { ...extras, ...timeoutExtras } : timeoutExtras
    const urlTree = RedirectTo.errorUrlTree(router, actualExtras)
    return router.navigateByUrl(urlTree, actualExtras)
  }

  public static verificationCodeSessionTimeout(router: Router): Promise<boolean> {
    const timeoutExtras = {
      state: {
        errorCode: ReponseErrors.VERIFICATION_CODE_SESSION_TIMEOUT,
        url: router.url
      }
    }
    const urlTree = RedirectTo.errorUrlTree(router, timeoutExtras)
    return router.navigateByUrl(urlTree, timeoutExtras)
  }

  public static installationsNotFound(router: Router): Promise<boolean> {
    const extras = {
      state: {
        errorCode: ReponseErrors.INSTALLATIONS_NOT_FOUND,
        url: router.url
      }
    }
    const urlTree = RedirectTo.errorUrlTree(router, extras)
    return router.navigateByUrl(urlTree, extras)
  }

  public static noAccess(router: Router, extras?: NavigationExtras): Promise<boolean> {
    return router.navigate(['/no-access'], extras)
  }

  public static serverBusy(router: Router, extras?: NavigationExtras): Promise<boolean> {
    return router.navigate(['/busy'], extras)
  }

  public static userBlocked(router: Router, extras?: NavigationExtras): Promise<boolean> {
    return router.navigate(['/blocked'], extras)
  }

  public static notFound(router: Router): Promise<boolean> {
    const notFoundTree = RedirectTo.notFoundUrlTree(router)
    return router.navigateByUrl(notFoundTree)
  }

  public static notFoundUrlTree(router: Router): UrlTree {
    return router.createUrlTree(['/not-found'])
  }

  public static errorUrlTree(router: Router, extras?: NavigationExtras): UrlTree {
    return router.createUrlTree(['/error'], extras)
  }

  public static readingFlowUrlTree(
    router: Router,
    // Not using the backend generated types for the "GetReadingPlan" endpoint
    // because this is going to be called from other places that aren't related
    // to this last endpoint
    readingType: 'monthly' | 'quarterly' | 'annual',
    customerDetails: ReadingCustomerDetails,
    redirectToWrongReceiver: boolean,
    isShortLink: boolean = false
  ): UrlTree {
    const readingTypePath = readingType === 'monthly' ? ReadingRoutes.monthlyReading : ReadingRoutes.annualReading
    let commands = [
      '/flow',
      readingTypePath
    ]
    if ((readingType === 'annual' || readingType === 'quarterly')  && redirectToWrongReceiver) {
      commands = [...commands, 'forkert-modtager']
    }

    const accKey = BaseReading.ACCOUNT_NUMBER_ROUTING_KEY
    const cardKey = BaseReading.READING_NUMBER_ROUTING_KEY
    const cardType = BaseReading.CARD_TYPE_ROUTING_KEY
    const shortLink = BaseReading.IS_SHORTLINK_IN_KEY
    const queryParams = {}
    queryParams[accKey] = customerDetails.accountNumber
    queryParams[cardKey] = customerDetails.readingCardNumber
    if (customerDetails.cardType) {
      queryParams[cardType] = customerDetails.cardType
    }
    queryParams[shortLink] = isShortLink ? '1' : '0'

    return router.createUrlTree(commands, {
      queryParams,
      queryParamsHandling: 'merge'
    })
  }

  // eslint-disable-next-line max-len
  public static readingFlow(router: Router, data: ShortLinkData, isShortLink: boolean = false) {
    const route = `/flow/${ReadingRoutes.reading}`
    const accKey = BaseReading.ACCOUNT_NUMBER_ROUTING_KEY
    const cardKey = BaseReading.READING_NUMBER_ROUTING_KEY
    const shortLink = BaseReading.IS_SHORTLINK_IN_KEY
    const redirectWrongReceiverKey = BaseReading.REDIRECT_WRONG_RECEIVER_ROUTING_KEY
    const queryParams = {}
    queryParams[accKey] = data.accountNumber
    queryParams[cardKey] = data.readingCardNumber
    queryParams[shortLink] = isShortLink ? '1' : '0'
    queryParams[redirectWrongReceiverKey] = data.redirectToUnknownReceiver

    router.navigate([route], {
      queryParams,
      queryParamsHandling: 'merge',
    })
  }

  public static xellentTimingOut(router: Router, extras?: NavigationExtras) {
    return router.navigate(['/', 'timeout'], extras)
  }

  public static expiredSession(router: Router, extras?: NavigationExtras) {
    return router.navigate(['/expired-session'], extras)
  }

  public static loginLandingPage(onRedirect?: () => void): boolean {
    RedirectTo.link(environment.links.login, onRedirect)
    return true
  }

  public static welcomeLandingPage(onRedirect?: () => void): boolean {
    RedirectTo.link(environment.links.welcome, onRedirect)
    return true
  }

  public static loginOrWelcomeLandingPage(isAuthenticated: boolean) {
    if (isAuthenticated) {
      RedirectTo.welcomeLandingPage()
    } else {
      RedirectTo.loginLandingPage()
    }
  }

  public static link(link: string, onRedirect?: () => void) {
    onRedirect?.()
    window.location.href = link
  }
}
