import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { ReadingRoutes } from 'src/app/soft-login/models/reading-routes.model'
import { EvidaEvents } from 'src/app/tracking/evida-events'
import { GoogleAnalytics } from 'src/app/tracking/google-analytics'

interface EvidaFaqLink {
  text: string;
  href: string;
  analytics: string;
}
@Component({
  selector: 'app-evida-faq',
  templateUrl: './evida-faq.component.html',
  styleUrls: ['./evida-faq.component.scss'],
})
export class EvidaFaqComponent {
  @Input() public title: string
  public faqLinks$: Observable<EvidaFaqLink[]>

  constructor(
    private router: Router,
    private translate: TranslateService
  ) { }

  @Input() public set links(v: string) {
    if (v) {
      this.faqLinks$ = this.translate.get(v)
    }
  }

  public onLinkClick(link: EvidaFaqLink) {
    // Track termination reading
    if (this.router.url.endsWith(ReadingRoutes.terminationReading)) {
      if (link.analytics !== null) {
        GoogleAnalytics.trackEvent(link.analytics)
      }
    } else {
      GoogleAnalytics.trackLink(EvidaEvents.FAQ.clicks.link, link)
    } 
  }
}
