import { concat, EMPTY, Observable, of, race, } from 'rxjs'
import {
  catchError, distinctUntilChanged, endWith,
  filter, map, mapTo, shareReplay, switchMap
} from 'rxjs/operators'


export const CORRELATION_ID_HEADER_NAME = 'X-Correlation-Id'
export const DATE_HEADER_NAME = 'Date'

/**
 * An aggregate that holds together:
 *
 * @property data$ the stream that has the information
 * @property error$ the stream that has the errors
 * @property loading$ the stream that indicates progress
 */
export interface Streams<T> {
  data$: Observable<T>;
  error$: Observable<any>;
  loading$: Observable<boolean>;
}

export const nullIfEmpty = (str: string) => str.length === 0 ? null : str
export class Utils {


  public static copyToClipboard(value: string) {
    const el = document.createElement('textarea')
    el.value = value
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  public static getCorrelationIdFromError(maybeErrorObject: any): string | null {
    if (!maybeErrorObject || !maybeErrorObject.headers) { return null }
    return maybeErrorObject.headers.get(CORRELATION_ID_HEADER_NAME)
  }

  public static getDateFromError(maybeErrorObject: any): string | null {
    const dateValueFromHeader = maybeErrorObject?.headers?.get(DATE_HEADER_NAME)
    const dateFromHeader = Date.parse(dateValueFromHeader)

    if (Number.isNaN(dateFromHeader)) {
      return 'Client: ' + new Date().toISOString()
    }

    const displayDate = new Date(dateFromHeader)
    return displayDate.toISOString()
  }

  public static capitalize(src: string): string {
    if (src.length === 0) {
      return src
    }
    const upperCasedFirstChar = src[0].toLocaleUpperCase()
    const theRest = src.slice(1)
    return `${upperCasedFirstChar}${theRest}`
  }

  /**
   * @returns an empty string if @param str is falsy
   */
  public static emptyIfNull(str: string | null | undefined): string {
    return str ? str : ''
  }

  public static orDefault<T>(input: T | null | undefined, deFault: T): T {
    // Weird naming because default is a keyword
    return input ? input : deFault
  }

  /**
   * Returns the unique @param items, any duplicates will be removed
   */
  public static unique<T>(items: Array<T>): Array<T> {
    return [... new Set(items)]
  }

  /**
   * @returns the first element of @param array or null if it was empty
   */
  public static firstOrNull<T>(array: Array<T>): T | null {
    return array.length >= 1 ? array[0] : null
  }

  public static streamsOf<T>(data$: Observable<T>): Streams<T> {
    const withCache = data$.pipe(shareReplay(1)) // It doesnt make sense to not cache server events

    // For any value, error or finalization happenning => we are not loading no-more.
    const isDataLoading = withCache.pipe(
      // Any emissions on data => we're not loading any more
      mapTo(false),
      // Any errors => we're not loading any more
      catchError(() => of(false)),
      // if no data or errors, we're done loading anyway.
      endWith(false),
      // If an observable emits a value and then completes
      // just have 1 emission
      distinctUntilChanged()
    )

    // We challenge to a race the server call and a hardcoded true.
    const loading = race(isDataLoading, of(true)).pipe(
      switchMap(isLoading => {
        // Race won by the hardcoded value because the other one always emits false.
        if (isLoading === true) {
          // So we concatenate the same value and the others coming later
          return concat(of(true), isDataLoading)
        } else {
          // Race won by the isDataLoading.
          // Just use it because we're already done loading
          return isDataLoading
        }
      }),
      distinctUntilChanged()
    )

    const errorStream = withCache.pipe(
      // Any correct emission is not an error emission
      map(_value => null),
      // Anything that is null is a valid emission
      filter(value => value != null),
      catchError(err => of(err))
    )

    const result = withCache.pipe(
      // If an error occurs, the data stream will have no emissions
      catchError(() => EMPTY),
    )

    return {
      data$: result,
      error$: errorStream,
      loading$: loading,
    }
  }

  public static scrollToTop() {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100)
  }

  public static blobToFile(blob: Blob, fileName: string, lastModified: Date): File {
    const b: any = blob

    b.lastModifiedDate = lastModified
    b.name = fileName

    return blob as File
  }

  public static fileToBlob(file: File): Blob {
    const b: any = file
    delete b.lastModifiedDate
    delete b.name

    return b as Blob
  }

  public static extractSubindex(unit: string | null | undefined): [string, string] {
    if (!unit) { return ['', ''] }
    const lastCharIndex = unit.length - 1
    const lastChar = unit[lastCharIndex]
    const isValidSubindex = Utils.isValidInteger(lastChar)
    return isValidSubindex ? [unit.substring(0, lastCharIndex), lastChar] : [unit, '']
  }

  public static isValidInteger(value: string): boolean {
    const parsedValue = Number.parseInt(value, 10)
    return !Number.isNaN(parsedValue)
  }

  public static orEmptyIfNull<T>(array: Array<T> | null | undefined): Array<T> {
    return array ? array : []
  }

  public static cprToBirthday(cpr: string): string {
    const array = cpr.match(/.{1,2}/g)
    const day = array[0]
    const month = array[1]
    const year = array[2]
    return `19${year}-${month}-${day}T00:00:00`
  }
}
